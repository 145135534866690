import { effect, inject, Injectable, signal } from '@angular/core'
import { CookieService } from 'ngx-cookie-service'

@Injectable({
  providedIn: 'root',
})
export class CookieConsentService {
  readonly cookieService = inject(CookieService)

  analyticCookie = signal(false)
  shownCookieConsentModal = signal(false)

  private gaLoaded = false
  private static gaScriptTag = 'ga-script'
  private static cookieAnalyticName = 'rm-analytics'
  private static cookieShownModalName = 'rm-shown-cookie-consent'

  constructor() {
    effect(() => {
      const analyticsCookie = this.analyticCookie()
      this.cookieService.set(CookieConsentService.cookieAnalyticName, analyticsCookie.toString(), {
        path: '/',
        expires: this.getCookieExpireTime(),
      })
      if (analyticsCookie) {
        this.loadGoogleAnalytics()
      } else {
        this.removeGoogleAnalytics()
      }
    })

    this.analyticCookie.set(this.cookieService.get(CookieConsentService.cookieAnalyticName) === 'true')
    this.shownCookieConsentModal.set(this.cookieService.get(CookieConsentService.cookieShownModalName) === 'true')
  }

  private getCookieExpireTime() {
    const expires = new Date()
    expires.setMonth(expires.getMonth() + 12)
    return expires
  }

  setShownCookieConsentModal(value: boolean) {
    this.cookieService.set(CookieConsentService.cookieShownModalName, value.toString(), {
      path: '/',
      expires: this.getCookieExpireTime(),
    })
    this.shownCookieConsentModal.set(value)
  }

  acceptAllCookies() {
    this.setShownCookieConsentModal(true)
    this.analyticCookie.set(true)
  }

  private loadGoogleAnalytics(): void {
    if (this.gaLoaded) {
      return
    }

    const script = document.createElement('script')
    script.id = CookieConsentService.gaScriptTag
    script.src = `https://www.googletagmanager.com/gtag/js?id=G-W2SC4VRJ47`
    script.async = true
    document.body.appendChild(script)

    /* eslint-disable */
    script.onload = () => {
      ;(window as any).dataLayer = (window as any).dataLayer || []

      function gtag() {
        ;(window as any).dataLayer.push(arguments)
      }
      ;(window as any).gtag = gtag
      // @ts-ignore
      gtag('js', new Date())
      // @ts-ignore
      gtag('config', 'G-W2SC4VRJ47')
    }
    /* eslint-enable */

    this.gaLoaded = true
  }

  private removeGoogleAnalytics(): void {
    const script = document.getElementById(CookieConsentService.gaScriptTag)
    if (script) {
      /* eslint-disable */
      script.remove()
      ;(window as any).gtag = null
      /* eslint-enable */
      this.gaLoaded = false
    }
  }
}
